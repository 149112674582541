import { useEffect, useState } from "react";
import { Product, ProductSerial } from "./erp/manufacturing/Product";
import { getDefaultSerialSetting, storeDefaultSerialSetting } from "./Settings";
import { ExtendedProduct } from "./erp/manufacturing/OdooOperationsService";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { IdWithDisplayName, StockQuant } from "@byteflies/odoo-typescript";
import { id, name, odooUrl, WH_STOCK } from "./erp/odoo/OdooUtils";

export class Serials {
  constructor(private serials: ProductSerial[]) {}

  filterByProduct(product: Product) {
    if (product === undefined || product.id === undefined) {
      throw new Error("product is invalid");
    }
    const clone = this.serials.filter((s) => s.product?.id === product.id);
    return new Serials(clone);
  }

  filterUnused() {
    const clone = this.serials
      .filter((s) => s.serial?.indexOf(" - scrapped") === -1)
      .filter((s) => s.serial?.indexOf(" - retired") === -1)
      .filter((s) => s.serial?.indexOf(" - depleted") === -1);
    return new Serials(clone);
  }

  findById(serialId: number) {
    return this.serials.find((s) => s.id === serialId);
  }

  findBySerial(serial: string) {
    return this.serials.find((s) => s.serial === serial);
  }

  length() {
    return this.serials.length;
  }

  all() {
    return this.serials;
  }
}
export interface SerialSelectionProps {
  product: Product;
  serials: Serials;
  stockQuants: StockQuant[];
  value: ProductSerial | undefined;
  onSerialSelected: (serial: ProductSerial) => void;
}

const disabled = (
  options: Serials,
  value: ProductSerial | undefined
): boolean => {
  return (
    options === null ||
    options === undefined ||
    options.length() === 0 ||
    options.length() === 1 ||
    (value === undefined && options.length() === 1)
  );
};

export interface BookmarkProps {
  product: Product;
  serial: ProductSerial | undefined;
}

function isBookmarked(product: Product, serial: ProductSerial | undefined) {
  if (serial === undefined || serial.id === undefined) {
    return false;
  }
  const productId = (product as ExtendedProduct).id;
  const defaultSerialId = getDefaultSerialSetting(productId);
  return defaultSerialId === serial?.id;
}

function ProductBookmark(props: BookmarkProps) {
  const { product, serial } = props;
  const [bookmarked, setBookmarked] = useState<boolean>(false);

  useEffect(() => {
    setBookmarked(isBookmarked(product, serial));
  }, [product, serial]);

  return (
    <IconButton
      data-cy="bookmark"
      disabled={
        product === undefined ||
        product.id === undefined ||
        isNaN(product.id) ||
        serial === undefined ||
        serial.id === undefined ||
        serial.id === -1
      }
      onClick={() => {
        if (bookmarked) {
          storeDefaultSerialSetting(product.id, -1);
        } else {
          storeDefaultSerialSetting(product.id, serial!.id);
        }
        setBookmarked(!bookmarked);
      }}
      size="large"
    >
      {product === undefined ||
      product.id === undefined ||
      serial === undefined ||
      serial.id === undefined ? (
        <BookmarkBorderIcon color="disabled" />
      ) : bookmarked ? (
        <BookmarkIcon color="primary" />
      ) : (
        <BookmarkBorderIcon />
      )}
    </IconButton>
  );
}

function SerialSelection(props: SerialSelectionProps) {
  const { product, onSerialSelected, value, serials, stockQuants } = props;
  const [myValue, setMyValue] = useState(value);

  function inStock(serial: ProductSerial, stockQuants: StockQuant[]) {
    if (
      stockQuants === undefined ||
      stockQuants === null ||
      stockQuants.length === 0
    ) {
      return false;
    }

    return (
      stockQuants
        .filter((sq) => id(sq.lot_id) === serial.id)
        .filter(
          (sq) =>
            name(sq.lot_id as any as IdWithDisplayName)?.indexOf(" - scrapped") === -1
        )
        .filter(
          (sq) =>
            name(sq.lot_id as any as IdWithDisplayName)?.indexOf(" - depleted") === -1
        )
        .filter(
          (sq) =>
            name(sq.lot_id as any as IdWithDisplayName)?.indexOf(" - retired") === -1
        )
        .filter((sq) => id(sq.location_id) === WH_STOCK)
        .filter((sq) => sq.quantity !== undefined)
        .filter((sq) => sq.quantity > 0).length > 0
    );
  }

  function serialToName(serial: ProductSerial, stockQuants: StockQuant[]) {
    if (stockQuants.length === 0) {
      // The quantities have not been loaded yet
      return `${serial?.serial}\t(No stock)`;
    }

    const serialIsInstock = inStock(serial, stockQuants);
    if (serialIsInstock === false) {
      return `${serial?.serial}\t(Not available)`;
    } else {
      return `${serial?.serial}`;
    }
  }

  useEffect(() => {
    setMyValue(value);
  }, [value]);

  if (
    (serials === undefined || serials.length() === 0) &&
    myValue !== undefined &&
    myValue.serial !== undefined &&
    myValue.id !== undefined
  ) {
    return (
      <Tooltip title={"No serials but value is selected"}>
        <Select
          data-testid="serial"
          data-cy="serial"
          size="small"
          fullWidth
          value={myValue.serial}
          disabled={true}
          endAdornment={
            product !== undefined &&
            myValue !== undefined &&
            serials !== undefined && (
              <InputAdornment position="end" style={{ marginRight: 20 }}>
                <ProductBookmark product={product} serial={myValue} />
              </InputAdornment>
            )
          }
        >
          <MenuItem
            data-cy={`serial-${myValue.serial}`}
            value={myValue.serial}
            key={myValue.serial}
            disabled={true}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={odooUrl("stock.lot", myValue.id!)}
            >
              {myValue.serial}
            </a>
          </MenuItem>
        </Select>
      </Tooltip>
    );
  } else if (
    serials.length() === 1 &&
    myValue !== undefined &&
    myValue.serial !== undefined &&
    myValue.id !== undefined
  ) {
    return (
      <Tooltip title={"Only one serial found and it is selected"}>
        <Select
          data-testid="serial"
          data-cy="serial"
          size="small"
          fullWidth
          value={myValue.serial}
          disabled={true}
        >
          <MenuItem
            data-cy={`serial-${myValue.serial}`}
            value={myValue.serial}
            key={myValue.serial}
            disabled={true}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={odooUrl("stock.lot", myValue.id!)}
            >
              {myValue.serial}
            </a>
          </MenuItem>
        </Select>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={`${serials.length()} serials found, ${serials
        .filterUnused()
        .length()} in use`}
    >
      <Select
        data-testid="serial"
        data-cy="serial"
        size="small"
        fullWidth
        value={serials.findBySerial(myValue!.serial!)?.serial || ""}
        endAdornment={
          product !== undefined &&
          myValue !== undefined &&
          serials !== undefined &&
          serials.length() > 1 && (
            <InputAdornment position="end" style={{ marginRight: 20 }}>
              <ProductBookmark product={product} serial={myValue} />
            </InputAdornment>
          )
        }
        disabled={disabled(serials, value)}
        onChange={(event) => {
          const selectedSerial = event.target.value as string;
          if (selectedSerial !== undefined && selectedSerial !== "") {
            const s = serials.findBySerial(selectedSerial);
            if (s !== undefined) {
              setMyValue(s);
              onSerialSelected(s);
            }
          }
        }}
      >
        {serials
          .filterUnused()
          .all()
          .map((option, index) => {
            return (
              <MenuItem
                data-cy={`serial-${
                  option !== undefined && option.serial !== undefined
                    ? option.serial
                    : index
                }`}
                value={
                  option !== undefined && option.serial !== undefined
                    ? option.serial
                    : ""
                }
                key={option.serial}
                disabled={myValue === undefined || option.id === myValue?.id}
              >
                {serialToName(option, stockQuants)}
              </MenuItem>
            );
          })}
      </Select>
    </Tooltip>
  );
}
export default SerialSelection;
