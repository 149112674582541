import { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OdooOperationsService, {
  ComparatorCriterium,
  OrCriterium,
} from "./erp/manufacturing/OdooOperationsService";
import {
  id,
  name,
  odooUrl,
  VIRT_LOST,
  VIRT_PRODUCTION,
  VIRT_SCRAP,
  WH_QUARANTAINE,
  WH_RETURNED_ITEMS,
  WH_STOCK,
} from "./erp/odoo/OdooUtils";
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  IdWithDisplayName,
  MrpUnbuild,
  StockMove,
  StockMoveLine,
} from "@byteflies/odoo-typescript";
import SerialSearchField from "./SerialSearchField";
import {
  Location,
  ProductionOrder,
  ProductSerial,
} from "./erp/manufacturing/Product";
import { CheckCircle, Error as ErrorIcon, Refresh } from "@mui/icons-material";
import { Box } from "@mui/system";
import TableRowsIcon from "@mui/icons-material/TableRows";

interface UnbuildProps {
  svc: OdooOperationsService;
}

interface ScannedProduct {
  serial: ProductSerial;
  po?: ProductionOrder | null;
  unbuildOrder?: MrpUnbuild;
  error?: Error;
  location?: Location;
  busy?: boolean;
  stockMoveLines?: StockMoveLine[];
}

interface UnbuildLine {
  stockMoveLine: StockMoveLine;
  missing?: boolean;
}

export function Unbuild(props: UnbuildProps) {
  const { svc } = props;
  const [scannedProducts, setScannedProducts] = useState<ScannedProduct[]>([]);
  const [fetchingProducts, setFetchingProducts] = useState<boolean>();
  const [unbuildLines, setUnbuildLines] = useState<UnbuildLine[]>();
  const [unbuildDialogError, setUnbuildDialogError] = useState<string>();
  const [correcting, setCorrecting] = useState<boolean>(false);

  const updateScannedProduct = (product: ScannedProduct) => {
    setScannedProducts(
      scannedProducts.map((p) => {
        if (p.serial.id === product.serial.id) {
          return product;
        } else {
          return p;
        }
      })
    );
  };

  const getStockMoveLines = async (
    unbuildOrderId: number
  ): Promise<StockMoveLine[]> => {
    return (
      await svc.listStockMoveLines({
        toDomain(): any[] {
          const unbuildId = new ComparatorCriterium(
            "move_id.unbuild_id",
            "=",
            unbuildOrderId
          );
          const consumeUnbuildId = new ComparatorCriterium(
            "move_id.unbuild_id",
            "=",
            unbuildOrderId
          );
          return new OrCriterium(unbuildId, consumeUnbuildId).toArray();
        },
      })
    ).filter((line) => id(line.location_dest_id) !== VIRT_PRODUCTION && !line.picking_id);
  };

  const getLocation = async (
    serialId: number
  ): Promise<Location | undefined> => {
    const stockQuants = (await svc.searchStockQuantsByLotId(serialId)).filter(
      (sq) => sq.quantity !== 0
    );
    if (stockQuants.length === 2) {
      const realStockQuant = stockQuants.find(
        (sq) => sq.quantity === 1
      );
      const prodStockQuant = stockQuants.find(
        (sq) =>
          sq.quantity === -1 && id(sq.location_id) === VIRT_PRODUCTION
      );
      if (realStockQuant && prodStockQuant) {
        return {
          id: id(realStockQuant.location_id as IdWithDisplayName)!,
          name: name(realStockQuant.location_id as IdWithDisplayName)!,
        };
      }
    }
  };

  const isValidLocation = (product: ScannedProduct) => {
    const locationId = id(product.location);
    if (locationId && [WH_RETURNED_ITEMS, WH_STOCK].includes(locationId)) {
      return true;
    } else {
      return false;
    }
  };

  const updateLocation = async (product: ScannedProduct) => {
    const productClone: ScannedProduct = {
      ...product,
      busy: true,
    };
    updateScannedProduct(productClone);
    try {
      productClone.location = await getLocation(productClone.serial.id);
    } catch (error) {
      console.log("Failed to update location", error);
      productClone.error = error as Error;
    } finally {
      productClone.busy = false;
      updateScannedProduct(productClone);
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Grid container style={{ padding: 24 }}>
        <Grid paddingBottom={2} item xs={8}>
          <Typography variant="h4">Unbuild</Typography>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: "flex" }}>
            <Button
              onClick={() => {
                setScannedProducts([]);
                inputRef.current && inputRef.current.focus();
              }}
            >
              Clear
            </Button>
            <SerialSearchField
              inputRef={inputRef}
              disabled={fetchingProducts}
              svc={svc}
              fullWidth={true}
              onError={(error) => {
                console.error("Something went wrong scanning serial", error);
              }}
              onSerialsSelected={async (serials) => {
                setFetchingProducts(true);
                const scannedProductsClone = [
                  ...scannedProducts.filter(
                    (p) =>
                      !serials.map((s) => s.serial.id).includes(p.serial.id)
                  ),
                ];
                for (const serial of serials) {
                  const product: ScannedProduct = {
                    serial: serial.serial,
                  };
                  try {
                    product.location = await getLocation(serial.serial.id);
                    if (svc.isOdooProductSerial(serial.serial)) {
                      product.po = await svc.searchProductionOrderBySerialId(
                        serial.serial.id,
                        true
                      );
                      if (!product.po?.id) continue;
                      const unbuildOrders = await svc.listUnbuildOrders({
                        toDomain(): any[] {
                          return [
                            new ComparatorCriterium(
                              "mo_id",
                              "=",
                              product.po?.id
                            ).toArray(),
                          ];
                        },
                      });
                      if (unbuildOrders.length > 1) {
                        throw new Error(
                          `Multiple unbuild orders found: ${JSON.stringify(
                            unbuildOrders.map(id)
                          )}`
                        );
                      } else if (unbuildOrders.length === 1) {
                        product.unbuildOrder = unbuildOrders[0];
                        product.stockMoveLines = await getStockMoveLines(
                          product.unbuildOrder.id!
                        );
                      }
                    }
                  } catch (error) {
                    product.error = error as Error;
                  } finally {
                    scannedProductsClone.push(product);
                  }
                }
                setScannedProducts(scannedProductsClone);
                setFetchingProducts(false);
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>PO</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Serial</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Unbuild</TableCell>
                  <TableCell>Order</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Error</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scannedProducts.map((p) => (
                  <TableRow key={p.serial.id}>
                    <TableCell>
                      {p.po && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={odooUrl("mrp.production", p.po.id)}
                        >
                          {p.po.name}
                        </a>
                      )}
                    </TableCell>

                    <TableCell>{p.serial.product.name}</TableCell>

                    <TableCell>
                      {p.serial && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={odooUrl("stock.lot", p.serial.id)}
                        >
                          {p.serial.serial}
                        </a>
                      )}
                    </TableCell>

                    <TableCell>
                      <Grid container spacing={1} alignItems="center">
                        {p.unbuildOrder?.state === "done" ? (
                          <Grid item>N/A</Grid>
                        ) : (
                          p.location?.name && (
                            <Grid item>{p.location.name}</Grid>
                          )
                        )}
                        <Grid item>
                          {p.unbuildOrder?.state === "done" ? (
                            <CheckCircle color="success" />
                          ) : isValidLocation(p) ? null : (
                            <ErrorIcon color="error" />
                          )}
                        </Grid>
                        {!p.unbuildOrder && (
                          <Grid item>
                            <IconButton
                              data-cy={"refresh-location"}
                              disabled={p.busy}
                              onClick={() => updateLocation(p)}
                            >
                              <Refresh />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>

                    <TableCell>
                      <Button
                        data-cy={"unbuild-po"}
                        variant="contained"
                        disabled={
                          p.unbuildOrder !== undefined ||
                          p.busy ||
                          !isValidLocation(p)
                        }
                        onClick={async () => {
                          const productClone: ScannedProduct = {
                            ...p,
                            error: undefined,
                            busy: true,
                          };
                          updateScannedProduct(productClone);

                          const unbuildOrder: MrpUnbuild = {
                            state: "draft",
                            product_id: p.serial.product.id,
                            product_qty: p.po?.quantity,
                            bom_id: p.po?.bom?.id,
                            mo_bom_id: p.po?.bom?.id,
                            mo_id: p.po?.id,
                            lot_id: p.serial.id,
                            company_id: p.serial.product?.company_id,
                            location_id: p.location?.id,
                            location_dest_id: WH_QUARANTAINE,
                            product_uom_id: 1,
                          } as MrpUnbuild;

                          console.log(
                            `Creating unbuild order`,
                            JSON.stringify(unbuildOrder)
                          );

                          try {
                            unbuildOrder.id = await svc.unbuild(
                              unbuildOrder,
                              false
                            );
                          } catch (error) {
                            console.log(
                              "Failed to create unbuild order",
                              error
                            );
                            productClone.error = error as Error;
                            productClone.busy = false;
                            updateScannedProduct(productClone);
                            return;
                          }

                          try {
                            await svc.validateUnbuildOrder(unbuildOrder.id);
                            productClone.unbuildOrder =
                              await svc.readUnbuildOrder(unbuildOrder.id);
                            productClone.stockMoveLines =
                              await getStockMoveLines(
                                productClone.unbuildOrder.id!
                              );
                            setUnbuildLines(
                              productClone.stockMoveLines.map((sml) => ({
                                stockMoveLine: sml,
                              }))
                            );
                          } catch (error) {
                            console.log(
                              "Failed to validate unbuild order",
                              error
                            );
                            productClone.error = error as Error;
                            const odooClient = svc.getOdooClient();
                            const odooSession = await svc.getOdooSession();
                            await odooClient.unlink(
                              odooSession,
                              [unbuildOrder.id],
                              "mrp.unbuild"
                            );
                          } finally {
                            productClone.busy = false;
                            updateScannedProduct(productClone);
                          }
                        }}
                      >
                        Unbuild
                      </Button>
                    </TableCell>

                    <TableCell>
                      {p.unbuildOrder?.id && (
                        <>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={odooUrl("mrp.unbuild", p.unbuildOrder?.id)}
                            style={{ paddingRight: 5 }}
                          >
                            {p.unbuildOrder.name}
                          </a>
                          {p.stockMoveLines !== undefined &&
                            p.stockMoveLines.length !== 0 && (
                              <IconButton
                                size="small"
                                onClick={() => {
                                  if (p.stockMoveLines) {
                                    setUnbuildLines(
                                      p.stockMoveLines.map((sml) => ({
                                        stockMoveLine: sml,
                                      }))
                                    );
                                  }
                                }}
                                sx={{ borderRadius: 0 }}
                              >
                                <TableRowsIcon />
                              </IconButton>
                            )}
                        </>
                      )}
                    </TableCell>

                    <TableCell>
                      {p.unbuildOrder?.state === "draft" ? (
                        <Chip
                          label="Draft"
                          disabled={p.busy}
                          onDelete={async () => {
                            const productClone: ScannedProduct = {
                              ...p,
                              busy: true,
                            };
                            updateScannedProduct(productClone);
                            try {
                              const odooClient = svc.getOdooClient();
                              const odooSession = await svc.getOdooSession();
                              await odooClient.unlink(
                                odooSession,
                                [p.unbuildOrder!.id!],
                                "mrp.unbuild"
                              );
                              productClone.unbuildOrder = undefined;
                            } catch (error) {
                              console.log(
                                "Failed to unlink build order",
                                error
                              );
                              productClone.error = error as Error;
                            } finally {
                              productClone.busy = false;
                              updateScannedProduct(productClone);
                            }
                          }}
                          color="warning"
                        />
                      ) : p.unbuildOrder?.state === "done" ? (
                        <Chip label="Done" color="success" />
                      ) : null}
                    </TableCell>

                    <TableCell>
                      {p.error?.message && (
                        <Alert severity="error">{p.error.message}</Alert>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {unbuildLines !== undefined && unbuildLines.length !== 0 && (
        <Dialog open maxWidth="xl">
          <DialogContent>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Lot/Serial Number</TableCell>
                    <TableCell>Destination Location</TableCell>
                    <TableCell>Present</TableCell>
                    <TableCell>Missing</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(
                    [...unbuildLines].sort((a, b) => {
                      return (
                        +(
                          id(
                            a.stockMoveLine.location_dest_id as IdWithDisplayName
                          )! === VIRT_SCRAP
                        ) -
                          +(
                            id(
                              b.stockMoveLine.location_dest_id as IdWithDisplayName
                            )! === VIRT_SCRAP
                          ) ||
                        name(
                          a.stockMoveLine.product_id as IdWithDisplayName
                        )!.localeCompare(
                          name(b.stockMoveLine.product_id as IdWithDisplayName)!
                        ) ||
                        name(
                          a.stockMoveLine.lot_id as IdWithDisplayName
                        )!.localeCompare(
                          name(b.stockMoveLine.lot_id as IdWithDisplayName)!
                        ) ||
                        b.stockMoveLine.id! - a.stockMoveLine.id!
                      );
                    }) ?? []
                  ).map((ul) => (
                    <TableRow key={ul.stockMoveLine.id}>
                      <TableCell>
                        {name(ul.stockMoveLine.product_id as IdWithDisplayName)}
                      </TableCell>
                      <TableCell>
                        {" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={odooUrl(
                            "stock.lot",
                            id(ul.stockMoveLine.lot_id as IdWithDisplayName)!
                          )}
                        >
                          {name(ul.stockMoveLine.lot_id as IdWithDisplayName)}
                        </a>
                      </TableCell>
                      <TableCell
                        sx={
                          id(
                            ul.stockMoveLine.location_dest_id as IdWithDisplayName
                          ) === VIRT_SCRAP
                            ? { color: "red" }
                            : {}
                        }
                      >
                        {name(ul.stockMoveLine.location_dest_id as IdWithDisplayName)}
                      </TableCell>
                      <TableCell>
                        {id(ul.stockMoveLine.location_dest_id) !==
                          VIRT_SCRAP && (
                          <Radio
                            size="small"
                            disableRipple
                            checked={!ul.missing}
                            onChange={() => {
                              ul.missing = false;
                              setUnbuildLines([...unbuildLines]);
                            }}
                            value="present"
                            name={String(ul.stockMoveLine.id)}
                            sx={{
                              padding: 0,
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {id(ul.stockMoveLine.location_dest_id) !==
                          VIRT_SCRAP && (
                          <Radio
                            size="small"
                            disableRipple
                            checked={ul.missing === true}
                            onChange={() => {
                              ul.missing = true;
                              setUnbuildLines([...unbuildLines]);
                            }}
                            value="missing"
                            name={String(ul.stockMoveLine.id)}
                            sx={{
                              padding: 0,
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button
              data-cy={"unbuild-correct"}
              variant="contained"
              color="warning"
              disabled={unbuildLines.every((ul) => !ul.missing)}
              onClick={async () => {
                setCorrecting(true);
                try {
                  const odooClient = svc.getOdooClient();
                  const odooSession = await svc.getOdooSession();
                  const missingLines = unbuildLines.filter((ul) => ul.missing);
                  const products = await odooClient.readProducts(
                    odooSession,
                    missingLines.map((ml) => id(ml.stockMoveLine.product_id)!)
                  );
                  for (const line of missingLines) {
                    const tracking = products.find(
                      (p) => p.id === id(line.stockMoveLine.product_id)
                    )?.tracking;
                    const newLocationDestId: IdWithDisplayName =
                      tracking === "serial"
                        ? {id: VIRT_LOST, display_name: "Virtual Locations/Lost"}
                        : {id: VIRT_SCRAP, display_name: "Virtual Locations/Scrap"};
                    line.stockMoveLine.location_dest_id = newLocationDestId;
                    const moveId = id(line.stockMoveLine.move_id);
                    if (!moveId) {
                      throw Error(
                        `Move ID is undefined, stock move line: ${JSON.stringify(
                          line.stockMoveLine
                        )}`
                      );
                    }
                    await svc.writeStockMove(moveId, {
                      location_dest_id: id(newLocationDestId),
                    } as StockMove);
                    await svc.writeStockMoveLine(line.stockMoveLine.id!, {
                      location_dest_id: id(newLocationDestId),
                    } as StockMoveLine);
                  }
                  setUnbuildLines([...unbuildLines]);
                } catch (e) {
                  const msg = (e as Error).message;
                  console.error(msg);
                  setUnbuildDialogError(msg);
                } finally {
                  setCorrecting(false);
                }
                setUnbuildLines(undefined);
              }}
            >
              Correct
              {correcting && (
                <>
                  &ensp;
                  <CircularProgress size="1em" />
                </>
              )}
            </Button>
            <Button
              data-cy={"unbuild-ok"}
              variant="contained"
              color="success"
              disabled={unbuildLines.some((ul) => ul.missing)}
              onClick={() => {
                setUnbuildLines(undefined);
              }}
            >
              OK
            </Button>
          </DialogActions>
          {unbuildDialogError && (
            <Alert severity="error">{unbuildDialogError}</Alert>
          )}
        </Dialog>
      )}
    </>
  );
}
