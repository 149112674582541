import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { MenuItem, Select, TextField } from "@mui/material";
import { Session } from "./erp/odoo/OdooClient";

export type Station =
  | "mercury"
  | "mars"
  | "test"
  | "uranus"
  | "saturn"
  | "venus"
  | "earth";

export const stations: { value: Station; label: string }[] = [
  {
    value: "mercury",
    label: "Mercury",
  },
  {
    value: "mars",
    label: "Mars",
  },
  {
    value: "test",
    label: "Test",
  },
  {
    value: "uranus",
    label: "Uranus",
  },
  {
    value: "saturn",
    label: "Saturn",
  },
  {
    value: "venus",
    label: "Venus",
  },
  {
    value: "earth",
    label: "Earth",
  },
];

export const kitchenAidMapping = {
  mercury: "kitchenaid-10000000072090b7",
  mars: "kitchenaid-10000000438ad537",
  test: "kitchenaid-1000000083d0c2d0",
  uranus: "kitchenaid-100000007c8d1e1f",
  saturn: "kitchenaid-1000000019a9ee00",
  venus: "kitchenaid-10000000d5f8f8b7",
  earth: "kitchenaid-10000000e87a9b2b",
};

export function getKitchenAidId(station: Station): string {
  return kitchenAidMapping[station];
}

export interface OdooSettings {
  url: string;
  dbName: string;
  fullname: string;
  username: string;
  password: string;
}

export function storeStation(station: Station) {
  localStorage.setItem("byteflies.settings.station.id", station);
}

export function storeDefaultSerialSetting(productId: number, serialId: number) {
  if (isNaN(productId)) {
    throw new Error("productId needs to be a number");
  }
  const key = `byteflies.settings.product.${productId}.defaultserial`;
  if (isNaN(serialId) || serialId === -1) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, `${serialId}`);
  }
}

export function getDefaultSerialSetting(productId: number): number | undefined {
  const s = localStorage.getItem(
    `byteflies.settings.product.${productId}.defaultserial`
  );
  if (s === null || s === undefined || s === "") {
    return undefined;
  } else {
    const serialId = +s;
    return serialId;
  }
}

export function getStation(): Station {
  const station = localStorage.getItem("byteflies.settings.station.id");
  if (station === undefined || station === null) {
    return "mercury";
  } else {
    return station as Station;
  }
}

export function storeOdooSettings(settings: OdooSettings) {
  clearOdooSession();

  localStorage.setItem("byteflies.settings.odoo.url", settings.url);
  localStorage.setItem("byteflies.settings.odoo.db", settings.dbName);
  localStorage.setItem("byteflies.settings.odoo.fullname", settings.fullname);
  localStorage.setItem("byteflies.settings.odoo.username", settings.username);
  localStorage.setItem("byteflies.settings.odoo.password", settings.password);
}

export async function storeOdooSession(
  session: Promise<Session>, renewalDate: Date
): Promise<void> {
  localStorage.setItem(
    "byteflies.settings.odoo.session",
    JSON.stringify(await session)
  );
  localStorage.setItem(
    "byteflies.settings.odoo.sessionRenewalDate",
    renewalDate.toISOString()
  );
}

export function clearOdooSession() {
  localStorage.removeItem("byteflies.settings.odoo.session");
}

export function getOdooSession(): Session | null {
  const session = localStorage.getItem("byteflies.settings.odoo.session");

  if (session !== null) {
    return JSON.parse(session) as Session;
  } else {
    return null;
  }
}

export function getOdooSessionRenewalDate(): Date | null {
  const sessionRenewalDateValue = localStorage.getItem("byteflies.settings.odoo.sessionRenewalDate");
  if (sessionRenewalDateValue) {
    return new Date(sessionRenewalDateValue);
  } else {
    return null;
  }
}

export function getOdooSettings(): OdooSettings | undefined {
  const url = localStorage.getItem("byteflies.settings.odoo.url");
  const db = localStorage.getItem("byteflies.settings.odoo.db");
  const fullname = localStorage.getItem("byteflies.settings.odoo.fullname");
  const username = localStorage.getItem("byteflies.settings.odoo.username");
  const password = localStorage.getItem("byteflies.settings.odoo.password");
  if (url === null) {
    return undefined;
  } else {
    return {
      url: url,
      dbName: db || "",
      fullname: fullname || "",
      username: username || "",
      password: password || "",
    };
  }
}

function Settings() {
  const existing = getOdooSettings() || {
    url: "https://odoo.kitchen.byteflies.com",
    dbName: "byteflies-kitchen-main-8701667",
    fullname: "",
    username: "",
    password: "",
  };

  const [odooUrl, setOdooUrl] = useState<string>(existing.url);
  const [odooDbName, setOdooDbName] = useState<string>(existing.dbName);
  const [odooFullname, setOdooFullname] = useState<string>(existing.fullname);
  const [odooUsername, setOdooUsername] = useState<string>(existing.username);
  const [odooPassword, setOdooPassword] = useState<string>(existing.password);
  const [station, setStation] = React.useState(getStation());

  return (
    <div>
      <Typography
        variant="h4"
        color="inherit"
        style={{ padding: 24, textAlign: "center" }}
      >
        Settings
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Odoo</Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={10}>
          <TextField
            size="small"
            data-cy="odoo-url"
            fullWidth
            label="Odoo URL"
            value={odooUrl}
            placeholder="https://odoo.kitchen.byteflies.com"
            onChange={(event) => {
              setOdooUrl(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={2} />
        <Grid item xs={10}>
          <TextField
            size="small"
            data-cy="odoo-db"
            fullWidth
            label="Odoo database"
            value={odooDbName}
            placeholder="byteflies-kitchen-main-8701667"
            onChange={(event) => {
              setOdooDbName(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={2} />
        <Grid item xs={10}>
          <TextField
            size="small"
            data-cy="odoo-fullname"
            fullWidth
            label="Full Name"
            placeholder="Surname FamilyName"
            value={odooFullname}
            onChange={(event) => {
              setOdooFullname(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={2} />
        <Grid item xs={10}>
          <TextField
            size="small"
            data-cy="odoo-username"
            fullWidth
            label="Username"
            placeholder="user@byteflies.com"
            value={odooUsername}
            onChange={(event) => {
              setOdooUsername(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={2} />
        <Grid item xs={10}>
          <TextField
            size="small"
            data-cy="odoo-password"
            fullWidth
            type="password"
            label="Password"
            value={odooPassword}
            onChange={(event) => {
              setOdooPassword(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Workstation</Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={10}>
          <Select
            data-cy="workstation"
            label="Workstation"
            value={station}
            onChange={(event) => {
              const s = event.target.value as Station;
              setStation(s);
            }}
          >
            {stations.map((station) => (
              <MenuItem key={station.value} value={station.value}>
                {station.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <Grid item xs={10}>
        <Button
          data-cy="save-settings"
          variant="contained"
          color="primary"
          onClick={() => {
            storeOdooSettings({
              url: odooUrl,
              fullname: odooFullname,
              username: odooUsername,
              password: odooPassword,
              dbName: odooDbName,
            });
            storeStation(station);
          }}
        >
          Save
        </Button>
      </Grid>
    </div>
  );
}

export default Settings;
