import { useState } from "react";
import Button from "@mui/material/Button";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";
import { ProductSerial } from "../erp/manufacturing/Product";
import OdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import {
  id,
  OPERATION_TYPE_QUALITY_CONTROL,
  WH_QUALITY_CONTROL,
} from "../erp/odoo/OdooUtils";
import { CallButtonResult } from "../erp/odoo/OdooClient";
import { QualityAlert } from "@byteflies/odoo-typescript";

interface QualityAlertButtonProps {
  svc: OdooOperationsService;
  serials: ProductSerial[];
  srcLocationId: number;
  disabled?: boolean;
  message?: string;
  qualityCheckId: number;
  qualityCheckTitle: string;
  pickingId?: number;
  onError(error: Error): void;
  onClick(): Promise<void>;
  onDone(qualityAlertName: string): void;
}

export function QualityAlertButton(props: QualityAlertButtonProps) {
  const {
    svc,
    serials,
    srcLocationId,
    disabled,
    message,
    qualityCheckId,
    qualityCheckTitle,
    pickingId,
    onError,
    onClick,
    onDone,
  } = props;

  const [state, setState] = useState<undefined | "busy" | "success" | "failed">(
    undefined
  );

  const [qualityAlertDialogOpen, setQualityAlertDialogOpen] =
    useState<boolean>(false);
  const [qualityAlertDescription, setQualityAlertDescription] =
    useState<string>("");
  const [qualityAlertName, setQualityAlertName] = useState<string>();

  return (
    <>
      <Button
        variant="contained"
        sx={{ backgroundColor: "#ffd300", color: "black" }}
        fullWidth
        disabled={disabled || state === "busy"}
        onClick={async () => {
          setQualityAlertDialogOpen(true);
        }}
      >
        {state === "busy" && <CircularProgress size="1em" />}
        {state === "failed" && <Warning />}
        {state === "success" && <CheckCircle />}
        &nbsp;Quality Alert{qualityAlertName ? ` (${qualityAlertName})` : null}
      </Button>
      {qualityAlertDialogOpen && (
        <Dialog
          open={true}
          onClose={() => {
            setQualityAlertDialogOpen(false);
          }}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Quality Alert{qualityAlertName ? ` (${qualityAlertName})` : null}</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              fullWidth
              minRows={3}
              maxRows={3}
              label="Description"
              onChange={(event) =>
                setQualityAlertDescription(event.target.value)
              }
              onKeyDown={(event) => event.stopPropagation()}
              margin="dense"
              disabled={qualityAlertName !== undefined}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setQualityAlertDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={qualityAlertDescription === "" || state === "busy"}
              onClick={async () => {
                setState("busy");
                try {
                  await onClick();
                  const odooClient = svc.getOdooClient();
                  const odooSession = await svc.getOdooSession();
                  const qualityAlertId = (
                    (await odooClient.callButtonIds(
                      odooSession,
                      "quality.check",
                      "do_alert",
                      [qualityCheckId]
                    )) as CallButtonResult
                  ).res_id;
                  if (!qualityAlertId) {
                    throw Error("Quality alert ID not found");
                  }
                  await odooClient.write(
                    odooSession,
                    qualityAlertId,
                    {
                      title: qualityCheckTitle,
                      description: qualityAlertDescription,
                      picking_id: pickingId,
                    } as any,
                    "quality.alert"
                  );
                  const qualityAlerts = await odooClient.read<QualityAlert[]>(
                    odooSession,
                    [[qualityAlertId], ["name"]],
                    "quality.alert"
                  );
                  if (qualityAlerts.length !== 1) {
                    throw Error("Could not find quality alert");
                  }
                  const newQualityAlertName = qualityAlerts[0].name;
                  if (!newQualityAlertName) {
                    throw Error("Quality alert name not found");
                  }
                  setQualityAlertName(newQualityAlertName);
                  const stockPicking = await svc.createStockPick(
                    serials,
                    id(OPERATION_TYPE_QUALITY_CONTROL)!,
                    true,
                    srcLocationId,
                    WH_QUALITY_CONTROL,
                  );
                  if (message) {
                    await svc.postMessage(
                      "stock.picking",
                      [stockPicking.id!],
                      message
                    );
                  }
                  onDone(newQualityAlertName);
                  setState("success");
                } catch (error) {
                  setState("failed");
                  onError(error as Error);
                }
              }}
            >
              {state === "busy" && <CircularProgress size="1em" />}
              &nbsp;Create
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
