import Grid from "@mui/material/Grid";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import {
  ProductSerial,
  QualityCheck,
  Product,
} from "../erp/manufacturing/Product";
import {
  getProductVersion,
  matrixUrl,
  qualityCheckCode,
  qualityCheckTitleWithoutCode,
} from "../Utils";
import { Alert, Typography } from "@mui/material";
import { ROUTE_SCRAP_WHEN_QUARANTINED } from "../Routes";
import { useCallback, useEffect, useState } from "react";

export function getHardwareVersion(product: Product | undefined) {
  if (product === undefined || product.name === undefined) {
    return "";
  }
  return getProductVersion(product);
}

interface QualityCheckHardwareVersionProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  serial: ProductSerial | undefined;
  onSuccess(): void;
  onFail(): void;
}

function QualityCheckHardwareVersion(props: QualityCheckHardwareVersionProps) {
  const { svc, qualityCheck, serial, onSuccess, onFail } = props;

  const [isAllowed, setIsAllowed] = useState<boolean>();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (qualityCheck.state === "pass" && event.key === "p") {
        onSuccess();
      }
    },
    [qualityCheck, onSuccess]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    (async () => {
      const routeIds = serial?.product.route_ids;
      if (!routeIds) {
        return;
      }
      if (routeIds.includes(ROUTE_SCRAP_WHEN_QUARANTINED.id)) {
        setIsAllowed(false);
        if (["none", undefined].includes(qualityCheck.state)) {
          await svc.saveQualityCheck(qualityCheck, false);
          qualityCheck.state = "fail";
          onFail();
        }
      } else {
        setIsAllowed(true);
        if (["none", undefined].includes(qualityCheck.state)) {
          await svc.saveQualityCheck(qualityCheck, true);
          qualityCheck.state = "pass";
          onSuccess();
        }
      }
    })();
  }, [onFail, onSuccess, qualityCheck, serial?.product.route_ids, svc]);

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Typography variant="h4">
            {qualityCheckTitleWithoutCode(qualityCheck)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {qualityCheck !== undefined && qualityCheck.id !== undefined && (
            <Typography variant="h4">
              <a
                target="_blank"
                rel="noreferrer"
                href={matrixUrl(qualityCheck)}
              >
                {qualityCheckCode(qualityCheck)}
              </a>
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          {isAllowed === true ? (
            <Alert severity="success" data-cy="hardware-is-allowed">
              Hardware version {getHardwareVersion(serial?.product)} is allowed
            </Alert>
          ) : isAllowed === false ? (
            <Alert severity="warning" data-cy="hardware-is-not-allowed">
              Hardware version {getHardwareVersion(serial?.product)} is NOT
              allowed
            </Alert>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

export default QualityCheckHardwareVersion;
