import {
  Command,
  GenericArgs,
  IdWithDisplayName,
  OdooModel,
  StockQuant,
} from "@byteflies/odoo-typescript";
import { isNotBlank } from "../../StringUtils";
import { ProductionOrder } from "../manufacturing/Product";

export const WH_STOCK = 8;
export const VIRT_INVENTORY_ADJUSTMENT = 14;
export const VIRT_PRODUCTION = 15;
export const VIRT_SCRAP = 16;
export const WH_QUARANTAINE = 30;
export const WH_COREDOT = 34;
export const WH_UNKNOWN = 31;
export const WH_CARDIOCARE_AT_HOME = 27;
export const WH_EPICARE_AT_HOME = 28;
export const WH_COVIDCARE_AT_HOME = 29;
export const VIRT_INVENTORY_ADJUSTMENTS = 14;
export const WH_RETURNED_ITEMS = 56;
export const PARTNER_CUSTOMERS = 5;
export const WH_QUALITY_CONTROL = 96;
export const VIRT_LOST = 97;

export const CONTENT_CHECK_QCP_ID = 121;

export const WH_BYTEFLIES_MANUFACTURING = 1;

export const COMPANY_ID_BYTEFLIES = 1;

export const OPERATION_TYPE_INTERNAL_TRANSFER: IdWithDisplayName = {
  id: 5,
  display_name: "Internal transfer",
};
export const OPERATION_TYPE_SERVICING: IdWithDisplayName = {
  id: 17,
  display_name: "Servicing",
};
export const OPERATION_TYPE_SDBT: IdWithDisplayName = {
  id: 29,
  display_name: "Sensor Dot Battery Testing",
}
export const OPERATION_TYPE_RETURN_USED_BOX: IdWithDisplayName = {
  id: 19,
  display_name: "Byteflies Manufacturing: Return Used Boxes",
}
export const OPERATION_TYPE_QUALITY_CONTROL: IdWithDisplayName = {
  id: 23,
  display_name: "Byteflies Manufacturing: Quality Control",
}

export const SERVER_ACTION_FIX_PRODUCTION_ORDER = 589;

export function productNameWithIntRef(
  intRef: string | undefined,
  name: string
) {
  if (isNotBlank(intRef)) {
    return `[${intRef}] ${name}`;
  } else {
    return name;
  }
}

export function productionOrderComplete(
  productionOrder: ProductionOrder | undefined
): productionOrder is ProductionOrder {
  if (
    productionOrder?.lines === undefined ||
    productionOrder.lines.length === 0 ||
    productionOrder?.product === undefined
  ) {
    return false;
  }
  for (const line of productionOrder.lines) {
    if (line.product_qty === 0) {
      continue;
    } else if (line.serial === undefined || line.serial.id === undefined) {
      return false;
    }
  }
  return true;
}

export function getCurrentLocation(
  quants: StockQuant[],
  destinationLocation?: number
) {
  const availableQuants = getCurrentLocations(quants);

  if (availableQuants.length === 0) {
    return undefined;
  } else if (availableQuants.length === 1) {
    return availableQuants[0];
  }

  for (const availableQuant of availableQuants) {
    if (id(availableQuant.location_id) === VIRT_SCRAP) {
      // When an item is scrapped, ignore all others
      return availableQuant;
    }
  }

  const availableQuantsWithoutInvAdj = availableQuants.filter(
    (q) => id(q.location_id) !== VIRT_INVENTORY_ADJUSTMENTS
  );

  const availableQuantsWithoutUnknown = availableQuants
    .filter((q) => id(q.location_id) !== WH_UNKNOWN)
    .filter((q) => id(q.location_id) !== VIRT_INVENTORY_ADJUSTMENTS);

  if (availableQuantsWithoutUnknown.length === 0) {
    return undefined;
  } else if (
    availableQuantsWithoutInvAdj.length > 1 &&
    destinationLocation !== undefined
  ) {
    const q = availableQuantsWithoutInvAdj.filter(
      (sq) => id(sq.location_id) !== destinationLocation
    );
    if (q.length > 0) {
      return q[0];
    }
  }

  if (availableQuantsWithoutUnknown.length > 1) {
    return undefined;
  }
  return availableQuantsWithoutUnknown[0];
}

export function getCurrentLocations(quants: StockQuant[]) {
  if (quants === undefined) {
    return [];
  }
  return (
    quants
      .filter((q) => q.quantity >= 1)
      .filter(
        (q) => q.location_id !== undefined
      )
      .filter((q) => id(q.location_id) !== VIRT_INVENTORY_ADJUSTMENT)
  );
}

export function id(
  id: null | undefined | false | number | object | Command<GenericArgs>
): number | undefined {
  if (id === null || id === undefined || id === false) {
    return undefined;
  } else if (typeof id === "number") {
    return id;
  } else if (
    Array.isArray(id) &&
    (id as any[]).length === 3 &&
    typeof id[0] === "number" &&
    typeof id[1] === "number" &&
    typeof (id as any[])[2] === "object"
  ) {
    const cmd = id as Command<GenericArgs>;
    const o = cmd[2] as GenericArgs;
    return o.id;
  } else if (Array.isArray(id) && typeof id[0] === "number") {
    return id[0];
  } else if (typeof id === "object" && Object.keys(id).includes("id")) {
    return (id as any).id as number;
  } else {
    return undefined;
  }
}

export function name(id: null |undefined | false | string | object): string | undefined {
  if (id === null || id === undefined) {
    return undefined;
  } else if (typeof id === "string") {
    return id;
  } else if (Array.isArray(id) && typeof id[1] === "string") {
    return id[1];
  } else if (typeof id === "object" && Object.keys(id).includes("display_name")) {
    return (id as any).display_name as string;
  } else {
    return undefined;
  }
}

export function odooUrl(model: OdooModel, id: number) {
  let url = "https://odoo.kitchen.byteflies.com";

  try {
    const localurl = localStorage.getItem("byteflies.settings.odoo.url");
    if (localurl !== null && localurl !== undefined && localurl !== "") {
      url = localurl;
    }
  } catch (error) {
    //Can be safely ignored
  }

  // https://byteflies-manufacturing-test-4.odoo.com/web#id=683&action=328&model=stock.production.lot&view_type=form&cids=1&menu_id=225
  // https://byteflies-manufacturing-test-4.odoo.com/web#id=4298&action=390&model=mrp.production&view_type=form&cids=1&menu_id=225
  // https://byteflies-manufacturing-test-4.odoo.com/web#id=272&action=396&model=product.product&view_type=form&cids=1&menu_id=225
  return `${url}/web#id=${id}&model=${model}&view_type=form&cids=1&menu_id=225`;
}

export function jiraUrl(issueId: string) {
  let i = issueId;
  const slash = i.indexOf("/");
  if (slash !== -1) {
    i = i.substring(0, slash);
  }
  return `https://byteflies.atlassian.net/browse/${i}`;
}