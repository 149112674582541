import { useState } from "react";

import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";
import { Product, ProductSerial, Tracking } from "../erp/manufacturing/Product";
import OdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { isBlank } from "../StringUtils";
import { productToTracking, productToGtin } from "../Utils";
import {
  CreateSerialRequest,
  CreateSerialResponse,
  createSerial,
} from "@byteflies/byteflies-serials";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {
  id,
  OPERATION_TYPE_INTERNAL_TRANSFER,
  VIRT_PRODUCTION,
  WH_STOCK,
} from "../erp/odoo/OdooUtils";

function getSerialOrLot(udi: CreateSerialResponse) {
  if (udi === undefined) {
    throw new Error("udi");
  }
  if (udi !== undefined && udi.serial !== undefined) {
    return udi.serial;
  } else if (udi !== undefined && udi.lot !== undefined) {
    return udi.lot;
  } else {
    throw new Error("serial or lot should be defined");
  }
}

interface NewSerialButtonProps {
  product: Product;
  svc: OdooOperationsService;
  onError(error: any): void;
  onSerialCreated(serial: ProductSerial): void;
  disabled?: boolean;
}

function NewSerialWithoutPoButton(props: NewSerialButtonProps) {
  const [successState, setSuccessState] = useState<
    undefined | "busy" | "success" | "failed"
  >(undefined);

  const { svc, product } = props;

  const disabled = (): boolean => {
    if (
      product.internalReference === undefined ||
      props.disabled ||
      isBlank(productToGtin(product)) ||
      productToTracking(product) === undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Tooltip title="Generate a new serial number and save it to odoo">
      <span>
        <IconButton
          data-cy={`generate-new-serial-${product.internalReference}`}
          color="primary"
          disabled={disabled()}
          onClick={async () => {
            setSuccessState("busy");
            try {
              const gtin = productToGtin(product);
              const productionDate = new Date();
              const tracking = productToTracking(product)!;
              const t =
                tracking === Tracking.ByUniqueSerialNumber ? "serial" : "lot";
              const request: CreateSerialRequest = {
                gtin: gtin,
                productionDate: productionDate,
                deviceId: undefined,
                type: t,
              };
              const serial = createSerial(request);

              const clonedSerial: ProductSerial = {
                udi: serial.udi,
                product: product,
                serial: getSerialOrLot(serial)!,
                creationDate: productionDate,
              } as ProductSerial;

              const odooSerial = await svc.createProductSerial(clonedSerial);
              console.log(
                "Created Odoo serial\n",
                JSON.stringify(odooSerial, null, 2)
              );
              const sp = await svc.createStockPick(
                [odooSerial],
                id(OPERATION_TYPE_INTERNAL_TRANSFER)!,
                true,
                VIRT_PRODUCTION,
                WH_STOCK,
              );
              console.log(
                "Moved Odoo serial from Production -> Stock\n",
                JSON.stringify(sp, null, 2)
              );

              setSuccessState("success");
              props.onSerialCreated(odooSerial);
            } catch (error) {
              console.error("Failed to get a new serial", error);
              setSuccessState("failed");
              props.onError(error);
            }
          }}
        >
          {successState === undefined && <QrCodeIcon />}
          {successState === "busy" && <CircularProgress size="1em" />}
          {successState === "failed" && <Warning />}
          {successState === "success" && <CheckCircle />}
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default NewSerialWithoutPoButton;
